@import "../../vars.css";

.container {
    color: var(--color-text);
    padding: 73px;
    z-index: 1;
    scroll-margin-top: 120px;
    background: var(--color-beige);
}

.reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: all 2s ease;
}

.reveal.active {
    transform: translateY(0px);
    opacity: 1; 
}

.title {
    font-size: 95px;
    font-weight: 700;
    letter-spacing: 1.75px;
    font-family: var(--font-berkshire);
    color: var(--color-black);
}

.projects {
    margin-top: 37px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 15px;
    width: 100%;
}

/* Mobile */
@media screen and (max-width: 1160px) {
    .container {
        margin-top: 0;
        padding:15px;
    }

    .title {
        font-size:55px;
        margin-left:10%;
        margin-top:10%;
    }

    .reveal.active {
        transform: inherit;
        height: fit-content;
    }
}