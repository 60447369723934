@import "../../vars.css";

.container {
    position: relative;
    padding: 73px;
    margin-top: 0;
    z-index: 1;
    scroll-margin-top: 120px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.reveal {
    position: relative;
    transform: translateY(150px);
    opacity: 0;
    transition: all 2s ease;
}

.reveal.active {
    transform: translateY(0px);
    opacity: 1; 
}

.title {
    color: var(--color-black);
    font-size: 10rem;
    text-align: center;
    font-weight: 700;
    letter-spacing: 1.75px;
    font-family: var(--font-berkshire);
    margin-top: 0;
    padding-bottom: 50px;
    margin-bottom: 0;
}

.content {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.textCntent h4 {
    margin-bottom: 0;
    text-decoration: underline;
    font-family: var(--font-berkshire);
    font-size: 20px;
    text-align: center;
}

.content p.mainAboutCopy {
    font-family: var(--font-barlow);
    font-size: 18px;
    text-align: center;
    width: 60%;
    margin: auto;
    margin-bottom: 50px;
}

.textCntent {
    display:flex;
}

.aboutImage {
    width: 35%;
    border-radius:20px;
}

.aboutItems {
    color: var(--color-black);
    display: flex;
    flex-direction: column;
    padding-left: 0;
    margin-top: 0;
}

.aboutItem {
    display: flex;
    flex-direction: row;
    align-items: center;
    border-radius: 20px;
    list-style: none;
    background-image: linear-gradient(90deg, rgba(165, 215, 232, 0.42) 0%, rgba(255, 255, 255, 0) 100%);
    background-size: 0 100%;
    background-repeat: no-repeat;
    transition: 0.4s;
    justify-content: center;
}

.aboutItem h3 {
    font-size: 25px;
    font-weight: 600;
}

.aboutItem p {
    font-size: 18px;
    font-family: var(--font-barlow);
    margin: 10px 0;
}

span.impText {
    font-family: var(--font-berkshire);
    color: var(--color-black);
    text-decoration: underline;
}  

.cursorIcon {
    width:30px;
    margin-right:15px;
}

.serverIcon {
    width:30px;
}

.sliding-div {
    opacity: 0;
    transform: translateX(-100%);
    transition: transform 1s ease-out, opacity 1s ease-out;
}
  
.sliding-div.visible {
    opacity: 1;
    transform: translateX(0);
}

/* Mobile */
@media screen and (max-width: 1160px) {
    .container {
        padding-left: 0;
        padding-right: 0;
        padding: 20px;
    }

    .content {
        flex-direction: column;
    }

    .aboutImage {
        display: none;
    }

    .aboutItems {
        margin-top: 0px;
        padding-left: 10px;
    }

    .aboutItem p {
        font-size: 20px;
    } 

    .aboutItem {
        padding-left: 0;
        padding-right: 0;
        width:90%;
        margin:auto;
        margin-left: 5px;
        margin-right: 0;
        text-align: center;
    }

    .title {
        font-size:55px;
        padding-bottom: 20px;
    }

    .content p.mainAboutCopy {
        padding-left: 10px;
        width: 90%;
        margin-bottom: 5px;
    }

    .textCntent h4 {
        padding-left: 10px;
    }

    .reveal.active {
        transform: inherit;
        height: fit-content;
    }
}