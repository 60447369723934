@import "../../vars.css";

.container {
    color: var(--color-black);
    background: var(--color-beige);
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: inherit;
    padding: 58px 10%;
    gap: 10px;
    scroll-margin-top: 120px;
}

.text h2 {
    font-size: 80px;
    font-weight: 700;
    letter-spacing: 4px;
    font-family: var(--font-berkshire);
    margin-bottom: 15px;
    margin-top: 15px;
}

.text p {
    font-size: 30px;
    font-weight: 400;
    letter-spacing: 2px;
    font-family: var(--font-barlow);
    margin-top: 5px;
    margin-bottom: 5px;
}

.links {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style: none;
    gap: 26px;
}

.link {
    display: flex;
    align-items: center;
    gap: 25px;
}

.link a {
    color: var(--color-black);
    font-family: var(--font-barlow);
    text-decoration: none;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1.5px;
}

.link img {
    width: 50px;
}

/* Mobile */
@media screen and (max-width: 1160px) {
    .container {
        flex-direction: column;
        gap: 0;
        padding: 20px;
        width: 90%;
        margin: auto;
        margin-left: 0;
        margin-right: 0;
        margin-top: 60px;
    }

    .text {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        margin-left:0;
    }

    .text h2 {
        font-size: 55px;
        margin-bottom:10px;
    }

    .text p {
        font-size: 24px;
    }

    .links {
        align-items: baseline;
        padding-left: 0;
    }

    .link {
        gap:10px;
    }

    .link a {
        font-size:14px;
    }
}