@import "../../vars.css";

.navbar {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    z-index: 2;
    background: var(--color-beige);
    width: 100%;
    padding: 5px 0;
    border-bottom: 2px solid #000;
}

.title {
    font-size: 30px;
    font-weight: 600;
    text-decoration: none;
    color: var(--color-black);
    margin:0 10%;
    font-family: var(--font-berkshire);
}

.menu {
    display: flex;
}

.menuBtn {
    display:none;
}

.menuItems {
    display: flex;
    gap: 47px;
    list-style: none;
    margin-right:50px;
}

.menuItems a {
    font-size: 16px;
    color: var(--color-black);
    text-decoration: none;
    font-family: var(--font-barlow);
    text-transform: uppercase;
}


/* Mobile */
@media screen and (max-width: 1160px) {
    .navbar {
        flex-direction: column;
        align-items: flex-end;
    }

    .menu {
        position: relative;
        right: 0;
        margin-right: 5%;
        flex-direction: column;
        align-items: flex-end;
        z-index: 3;
        width: 100%;
    }

    .menuBtn {
        display: block;
        cursor: pointer;
        width: 35px;
    }

    .menuItems {
        display: none;
        flex-direction: column;
        align-items: center;
        gap: 13px;
        border-radius: 10px;
        padding: 24px 33px;
    }

    .menuOpen {
        display: flex;
        z-index: 1;
        margin-right: 0;
        padding: 10px 33px;
        width: 75%;
    }
}