@import "../../vars.css";

.container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-beige);
    z-index: 1;
    height: 100vh;
    margin-top: 0;
    gap: 10px;
}

span.impText {
    font-family: var(--font-berkshire);
    color: var(--color-light-green);
    text-decoration: underline;
} 

.content {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: var(--color-black);
    z-index: 1;
    text-align: center;
    width: 40%;
}

.title {
    font-size: 115px;
    font-weight: 900;
    font-family: var(--font-laisha);
    margin-bottom: 0;
    margin-top: 0;
}

.secondTitle {
    font-size: 30px;
    font-weight: 900;
    margin-bottom: 33px;
    font-family: var(--font-laisha);
    margin-top: 0;
    margin-bottom: 0;
}

.description {
    font-size: 25px;
    font-family: var(--font-barlow);
    margin-bottom: 25px;
    margin-right: 40px;
    line-height:40px;
}

.contactBtn {
    text-decoration: none;
    background-color: var(--color-black);
    border: 2px solid var(--color-black);
    color: var(--color-text);
    border-radius: 100px;
    font-size: 20px;
    font-weight: 600;
    padding: 17px 26px;
    font-family: var(--font-barlow);
}
.contactBtn:hover {
    background-color: var(--color-text);
    color: var(--color-black);
}

.heroImg {
    width: 500px;
    z-index: 1;
    padding: 65px;
}

.pinPoint {
    width: 50px;
    top: 12px;
    position: relative;
}

@keyframes floating {
    0% {
        transform: translate(0, 0px);
    }
    50% {
        transform: translate(0, 10px);
    }
    100% {
        transform: translate(0, -0px);
    }
}

/* Mobile */
@media screen and (max-width: 1160px) {
    .container {
        flex-direction: column-reverse;
        height: fit-content;
        padding-top: 25%;
        padding-bottom: 10%;
    }

    .content {
        width: 90%;
    }

    .heroImg {
        width: 80%;
        padding: 0;
    }

    .title {
        font-size:50px;
        margin-top:33px;
        margin-bottom: auto;
    }

    .description {
        font-size: 19px;
        margin-bottom: 33px;
        margin-right: 0px;
        line-height: 33px;
    }

}